exports.strings = {
    appName: "Flight",
    loginTitle: "Login to Flight Playbooks",
    loginPreInviteButton: "Or ",
    loginRequestInvite: "sign up for free.",
    organizationsOverviewTitle: "Your Organizations",
    homeGreeting: "Welcome back,",
    products: "Products",
    sellers: "Sellers",
    newProductModalTitle: "Create a New Product",
    newProductModalDescription: "Start by giving this product a name or codename.",
    newProductModalAction: "Create",
    recentProductsTitle: "Products",
    recentProductsSubtitle: "Recently edited products.",
    newOrganizationFormTitle: "Enter Your Organization Name",
    newOrganizationFormDescription: "All your playbooks will be accessible to you and your collaborators through this unique URL.",
    newOrganizationFormAction: "Create Organization",
    shareDialogTitle: 'Collaboration',
    shareDialogDescription: "Add collaborators and choose how you want to control access to this organization. Users you add here will have access to your organization's Fligth portal and all of its products.",
    product: {
        quickActions: {
            quickAction1Title: 'Positioning',
            quickAction1Description: "Add your positioning to increase the quality and accuracy of content.",
            quickAction2Title: 'Market Trends',
            quickAction2Description: "Add market or industry trends to reference in playbooks and assets.",
            quickAction3Title: 'Customer Stories',
            quickAction3Description: "Add customer stories to insert into playbooks and assets.",
            quickAction4Title: 'Company Info',
            quickAction4Description: "Add your company name, website, and logo.",
        }
    },
    collaborators: {
        collaboratorsWidgetTitle: 'Collaborators',
        collaboratorsWidgetDescription: 'Add collaborators to work with others on positioning and sales content.',
    },
    playbooks: {
        title: 'Sales Playbooks',
        newPlaybookModalTitle: 'Create a New Sales Playbook',
        newPlaybookModalDescription: 'Start with the basics.',
        newPlaybookModalPlaceholder: 'Playbook Name',
        newPlaybookModalAction: 'Create',
        recentPlaybooksTitle: 'Recent Playbooks',
        newPlaybookModalNoPositioningTitle: 'Oh no!',
        newPlaybookModalNoPositioningHelper: 'It looks like you haven\'t created a positioning framework for this product yet. Please do so before creating a playbook.',
        newPlaybookModalNoPositioningAction: 'Create Positioning',
    },
    assets: {
        title: 'Sales Assets',
        newAssetModalNoPositioningTitle: 'Oh no!',
        newAssetModalNoPositioningHelper: 'It looks like you haven\'t created a positioning framework for this product yet. Please do so before creating a sales asset.',
        newAssetModalNoPositioningAction: 'Create Positioning',
    },
    authentication: {
        inviteCodeLabel: "",
        inviteCodePlaceholder: "Invide Code",
        emailLabel: "Email Address",
        emailPlaceholder: "",
        passwordLabel: "Choose Password",
        passwordPlaceholder: "",
        confirmPasswordLabel: "Confirm Password",
        confirmPasswordPlaceholder: "",
        firstNameLabel: "First Name",
        firstNamePlaceholder: "",
        lastNameLabel: "Last Name",
        lastNamePlaceholder: "",
        organizationSizeLabel: 'Organization Size',
        companyIndustryLabel: 'Industry',
        jobTitleLabel: 'Job Title',
    },
    positioning: {
        preTitle: '',
        title: 'Positioning',
        subTitle: '',
        introTitle: "Let's get started",
        introParagraph: "Before we can create any sales assets, we need to know how you talk about your product. Don't worry, we've made this really easy.",
        blankPositioningAction: 'Start Blank',
        blankPositioningDescription: 'Input your messaging using our best-in-class positioning framework.',
        quickStartPositioningAction: 'Quick Start',
        quickStartPositioningDescription: 'Use AI to create your positioning from a blogpost, messaging document, or press release.',
        forms: {
            productOverviewTitle: "Product Overview",
            productOverviewDescriptor: "Tell us the basics about your product. The more details you provide, the better for our AI co-pilot.",
            customerFacingProductNameLabel: "Customer facing product name",
            customerFacingProductNamePlaceholder: "Airbus A380",
            oneSentenceDescriptorLabel: "Once sentence product descriptor",
            oneSentenceDescriptorPlaceholder: "This is where we will have a really great example of a descriptor",
            audienceTypeLabel: "",
            audienceTypePlaceholder: "",
            quickStartOverviewTitle: "Quick Start Positioning",
            quickStartOverviewDescriptor: "We'll use AI to start a positioning framework from content you already have. Simply paste in a blog post, press release, messaging document, or any text that talks about your product.",
        }
    },
    notebook: {
        preTitle: '',
        title: 'Notebook',
        subTitle: '',
        introTitle: "Let's get started",
        introParagraph: "Before we can create any sales assets, we need to know how you talk about your product. Don't worry, we've made this really easy.",
        blankPositioningAction: 'Start Blank',
        blankPositioningDescription: 'Input your messaging using our best-in-class positioning framework.',
        quickStartPositioningAction: 'Quick Start',
        quickStartPositioningDescription: 'Start by uploading your messaging. We will use AI to create your positioning from a blogpost, messaging document, or press release.',
        forms: {
            quickStartModalTitle: "Tell Flight Your Positioning",
            quickStartModalDescription: "Start by uploading your messaging. We will use AI to create your positioning from a blogpost, messaging document, or press release.",
            quickStartModalUploadAction: "Upload",
            quickStartModalPasteAction: "Copy and Paste",
            productOverviewTitle: "Product Overview",
            productOverviewDescriptor: "Tell us the basics about your product. The more details you provide, the better for our AI co-pilot.",
            customerFacingProductNameLabel: "Customer facing product name",
            customerFacingProductNamePlaceholder: "Airbus A380",
            oneSentenceDescriptorLabel: "Once sentence product descriptor",
            oneSentenceDescriptorPlaceholder: "This is where we will have a really great example of a descriptor",
            audienceTypeLabel: "",
            audienceTypePlaceholder: "",
            quickStartOverviewTitle: "Quick Start Positioning",
            quickStartOverviewDescriptor: "We'll use AI to start a positioning framework from content you already have. Simply paste in a blog post, press release, messaging document, or any text that talks about your product.",
        }
    },
    playbookEditor: {
        preTitle: '',
        title: 'Playbook',
        subTitle: '',
        addModuleButton: 'Add Section',
    }
}