import React, { useEffect, useState } from "react";
import BasicPageTemplate from "../../../ux/BasicPageTemplate";
import useNavBarTitle from "../../../../utils/hooks/useNavBarTitle";
import { useSelector } from "react-redux";
import { get } from "lodash";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { strings } from "../../../../utils/strings/en-us";
import {
  DocumentCheckIcon,
  PencilIcon,
  PuzzlePieceIcon,
} from "@heroicons/react/24/outline";
import FormFieldSet from "../../../ux/FormFieldSet";
import { Controller, useForm } from "react-hook-form";
import QuickStartBenefitsMatrix from "./QuickStartBenefitsMatrix";
import { Card } from "../../../TailwindStyles/TailwindForms";
import InlineFullLoader from "../../../ux/inlineFullLoader";
import { useDispatch } from "react-redux";
import { saveRevisedPositioning } from "../../../../utils/redux/actions/dataActions/notebookActions";
import { selectedProductData } from "../../../../utils/redux/actions/dataActions";

const formFields = [
  {
    id: 0,
    name: "The Basics",
    description:
      "Tell us about your product. Flight will use this information to create sales playbooks and assets. It's recommended to use customer facing copy.",
    icon: PencilIcon,
    color: "bg-blue-500",
    fields: [
      {
        fieldName: "name",
        label: "Customer Facing Product Name",
        type: "text",
        placeholder: "Airbus A380",
        isRequired: true,
        maxLength: 200,
        minLength: 1,
      },
      {
        fieldName: "tagline",
        label: "Tagline",
        type: "text",
        placeholder:
          "Powering Predictive Intelligence for Progressive Businesses",
        isRequired: true,
        maxLength: 200,
        minLength: 1,
      },
      {
        fieldName: "sellingTo",
        label: "Who is this product for?",
        type: "radio",
        options: [
          { value: "B2B", label: "Organizations" },
          { value: "B2C", label: "Consumers" },
        ],
        defaultValue: "B2B",
        columns: 2,
        isRequired: true,
        maxLength: 200,
        minLength: 1,
      },
      {
        fieldName: "valueProposition",
        label: "Value Proposition",
        type: "textarea",
        placeholder:
          "InsightEngine is an advanced predictive analytics tool designed to decode intricate data sets and transform them into accessible and actionable insights. It harnesses the power of artificial intelligence and machine learning to generate precise forecasts, providing an invaluable asset for data-driven decision-making processes. Its robust functionality and user-friendly interface simplify complex data interpretation, enabling more accurate strategic planning. With InsightEngine, you have a reliable tool that makes data work for you, enhancing your operational efficiency and competitive edge.",
        isRequired: true,
        rows: 3,
        maxLength: 2000,
        minLength: 1,
      },
      {
        fieldName: "oneSentenceDescription",
        label: "Product Category",
        type: "text",
        rows: 1,
        placeholder: "Predictive analytics and business intelligence software.",
        isRequired: true,
        maxLength: 500,
        minLength: 1,
      },
      {
        fieldName: "keyDifferentiator",
        label: "Key Differentiator",
        type: "text",
        placeholder:
          "InsightEngine sets the standard with the most advanced AI-powered predictive analytics, translating complex data into actionable insights with unprecedented precision and ease.",
        isRequired: true,
        maxLength: 1000,
        minLength: 1,
      },
      {
        fieldName: "toneOfVoice",
        label: "Tone of Voice",
        type: "multiSelect",
        options: [
          { value: "friendly", label: "Friendly" },
          { value: "professional", label: "Professional" },
          { value: "fun", label: "Fun" },
          { value: "inspirational", label: "Inspirational" },
          { value: "serious", label: "Serious" },
          { value: "witty", label: "Witty" },
        ],
        defaultValue: "friendly",
        columns: 3,
        isRequired: true,
        maxLength: 200,
        minLength: 1,
      },
    ],
  },
  {
    id: 1,
    name: "Benefits and Features",
    description:
      "Enter your key benefits, proof points, and features of your product. You might also refer to your key benefits as marketing or positioning pillars. It’s recommended to use customer facing copy.",
    icon: PuzzlePieceIcon,
    color: "bg-green-500",
    fields: [
      {
        fieldName: "benefitsFeaturesMatrix",
        type: "benefitsFeaturesMatrixHookForm",
      },
    ],
  },
];

export default function QuickStartReview() {
  //////////////////////////////////
  // Hooks
  //////////////////////////////////
  const params = useParams();
  const data = useSelector((state) => state.v2Data);
  const product = get(data, `products.${params.productId}`, {});
  const setupNavBar = useNavBarTitle(
    "",
    `${product && product.name} ${strings.notebook.title}`,
    "",
    true,
    40
  );
  const quickstartWizardResponse = get(
    data,
    `products.${params.productId}.notebook.quickstartWizardResponse`,
    {
      benefitsFeaturesMatrix: {
        benefits: {},
        features: {},
        proofpoints: {},
        benefitOrder: [],
      },
    }
  );
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    reset,
    setError,
    clearErrors,
    control,
    formState: { errors },
  } = useForm({ defaultValues: quickstartWizardResponse });
  const dispatch = useDispatch();
  const [step, setStep] = useState("waiting");
  const [initialValues, setInitialValues] = useState(quickstartWizardResponse);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  //////////////////////////////////
  // Form Actions
  //////////////////////////////////

  const preventFormSubmit = (e) => {
    e.preventDefault();
  };

  const onSubmit = async (data) => {
    
    

    // Check that the positioning contains at least one benefit and one feature
    if (Object.keys(data.benefitsFeaturesMatrix.benefits).length === 0) {

      setError("benefitsFeaturesMatrix", {
        type: "custom",
        message: `Please add at least one benefit.`,
      });
      return;
    } else {
      clearErrors("benefitsFeaturesMatrix");
    }

    if (Object.keys(data.benefitsFeaturesMatrix.features).length === 0) {
      setError("benefitsFeaturesMatrix", {
        type: "custom",
        message: `Please add at least one feature.`,
      });
      return;
    } else {
      clearErrors("benefitsFeaturesMatrix");
    }

    // Disable Form, Show Loading
    setStep("publishing");

    // Send form data
    const sendData = await dispatch(
      saveRevisedPositioning({
        productId: params.productId,
        organizationId: params.organizationId,
        publish: true,
        ...data,
      })
    );

    const successNavigate = () => {
      let source = searchParams.get("source");
      if (source === "orgHome") {
        return `/${params.organizationId}`
      } else if (source === "playbook") {
        return `/${params.organizationId}/${params.productId}/playbook/${searchParams.get("id")}/edit`
      } else {
        return `/${params.organizationId}`
      }
    };

    // Check Response
    if (sendData.success) {
      setStep("waiting");
      navigate(successNavigate())
      const getProduct = await dispatch(
        selectedProductData({
          productId: params.productId,
          organizationId: params.organizationId,
        })
      );
    } else {
      setStep("waiting");
      setError("error", { type: "custom", message: `${sendData}` });
    }
  };

  const handlePublish = async () => {
    handleSubmit(onSubmit)();
  };

  //////////////////////////////////
  // if someone refreshes the page, we need to load the original data.
  //////////////////////////////////
  useEffect(() => {
    if (product.detailedLoad && !quickstartWizardResponse.name) {
      reset(product.positioning);
    }
  }, [product]);

  return product.detailedLoad ? (
    <BasicPageTemplate
      preTitle={strings.notebook.preTitle}
      title={`${product && product.name} ${strings.notebook.title}`}
      subTitle={strings.notebook.subTitle}
    >
      <form
        noValidate
        className="space-y-6"
        id="registration-form"
        onSubmit={preventFormSubmit}
      >
        {formFields.map((fieldSet, index) => {
          return (
            <FormFieldSet
              key={index}
              fieldSet={fieldSet}
              register={register}
              errors={errors}
              watch={watch}
              control={control}
              requireFields={true}
              setValue={setValue}
            />
          );
        })}

<div className="bg-white shadow left-0 right-0 bottom-0 p-4 z-10 fixed flex justify-end"> 
        <button
          type="button"
          id="submitbutton-positioning"
          disabled={step !== "waiting"}
          onClick={handlePublish}
          className={`shadow-sm w-36 justify-center relative -ml-px items-center rounded-md border border-gray-100  px-4 py-2 text-sm font-medium text-gray-100 hover:bg-blue-800 focus:z-10 focus:border-blue-600 focus:outline-none focus:ring-1 focus:ring-blue-600 inline-flex
                  ${step === "publishing" ? "bg-gray-300" : "bg-blue-500"}`}
        >
          <DocumentCheckIcon
            className="mr-2.5 h-5 w-5 text-gray-100"
            aria-hidden="true"
          />
          <span>{step === "publishing" ? "Submitting..." : "Submit"}</span>
        </button>
        </div>
      </form>
    </BasicPageTemplate>
  ) : (
    <InlineFullLoader />
  );
}
