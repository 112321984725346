import React from 'react'
import { Link } from 'react-router-dom'
import GlobalLogoV2 from '../../assets/brand/GlobalLogoV2'
import Logo from '../../assets/brand/Logo'
import { strings } from '../../utils/strings/en-us'

export default function LoginHeader() {
  return (
    <>
    <div className="w-72">
      <Logo hideText />    
    </div>
    <h2 className="mt-6 text-3xl font-bold tracking-tight text-gray-900">
      {strings.loginTitle}
    </h2>
    <p className="mt-2 text-sm text-gray-600">
      {strings.loginPreInviteButton}
      <Link
        to="/register"
        className="font-medium text-blue-600 hover:text-blue-500"
      >
        {" "}
        {strings.loginRequestInvite}
      </Link>
    </p>
  </>
  )
}
