import { get } from "lodash";
import { useSelector } from "react-redux";
import Login from "../pages/authentication/Login";
import LoginV2 from "../pages/authentication/LoginV2";
import VersionAccessComingSoon from "../pages/comingsoon/VersionAccessComingSoon";

export const ProtectedRoute = ({ children }) => {

  const user = useSelector((state) => state.user);
  let authenticated = get(user, "authenticated");
  let build = get(user, "credentials.build", []);

  let isAllowed = false;
  let thisVersion = "playbooks";

  if (build.includes(thisVersion)) {
    isAllowed = true;
  }

  if (authenticated) {
    return isAllowed
      ? children
      : <VersionAccessComingSoon />;
  } else {
    return <LoginV2 />;
  }
};
