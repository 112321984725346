import "./App.css";
import { getAuth, onAuthStateChanged } from "firebase/auth";

import { Provider } from "react-redux";
import store from "./utils/redux/store";
import { getUserData } from "./utils/redux/actions/userActions";
import FullScreenLoader from "./components/ux/FullScreenLoader";
import "react-loading-skeleton/dist/skeleton.css";
import { IntercomProvider } from "react-use-intercom";
import Notification from "./components/ux/Notification";
import AppRoutes from "./utils/AppRoutes";


// Authentication Check
const auth = getAuth();
onAuthStateChanged(auth, (user) => {
  if (user) {
    store.dispatch(getUserData());
  } else {
    store.dispatch({ type: "SET_AUTH_STATE_KNOWN" });
    store.dispatch({ type: "STOP_FULL_SCREEN_LOADING" });
  }
});

// Init Intercom
const INTERCOM_APP_ID = "k9t9skyd";

//App
function App() {
  return (
    <IntercomProvider appId={INTERCOM_APP_ID}>
      <Provider store={store}>
        <div className="App">
          <FullScreenLoader />
          <AppRoutes />
          <Notification />
        </div>
      </Provider>
    </IntercomProvider>
  );
}

export default App;
