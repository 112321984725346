import { getFunctions, httpsCallable } from "firebase/functions";
import { useId } from "react";
import { useIntercom } from "react-use-intercom";
import { selectedProductData } from "../dataActions";
const functions = getFunctions();

// Update Section Order
export const updateSectionOrder = (data) => async (dispatch) => {
  const updateSectionOrder = dispatch({
    type: "UPDATE_SECTION_ORDER",
    payload: data,
  });
  return updateSectionOrder;
};

// Add Section
export const addModule =
  (type, title, playbookId, productId, organizationId, content) =>
  async (dispatch) => {
    const uniqueId = Math.random().toString(36).substr(2, 9);

    let sectionId;

    if (type === "blank") {
      sectionId = uniqueId;
    } else if (type === "linked") {
      sectionId = uniqueId;
    } else {
      sectionId = type;
    }

    let section = {
      playbookId: playbookId,
      productId: productId,
      organizationId: organizationId,
      sectionId: sectionId,
      title: `${title}`,
      type: type,
      description: "This is a description of the section",
      content: { ...content },
      attachments: [],
      accurateAsOfDate: new Date().toISOString(),
      edit: true,
    };

    const addModule = await dispatch({
      type: "ADD_MODULE",
      payload: section,
    });

    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }

    return addModule;
  };

// Set "edit" to false
export const setEditToFalse = (playbookId, sectionId) => async (dispatch) => {
  const setEditToFalse = await dispatch({
    type: "SET_EDIT_TO_FALSE",
    payload: {
      playbookId: playbookId,
      sectionId: sectionId,
    },
  });
  return setEditToFalse;
};

// Restore Section
export const restoreSection = (section) => async (dispatch) => {
  const addModule = await dispatch({
    type: "RESTORE_MODULE",
    payload: section,
  });

  const element = document.getElementById(section.sectionId);
  if (element) {
    element.scrollIntoView({ behavior: "smooth" });
  }

  return addModule;
};

// Delete Section
export const deleteSection = (playbookId, sectionId) => async (dispatch) => {
  let payload = {
    playbookId: playbookId,
    sectionId: sectionId,
  };

  const deleteSection = dispatch({
    type: "DELETE_MODULE",
    payload: payload,
  });
  return deleteSection;
};

// Create Playbook TODO: Add success confirmation in the component
export const createPlaybook = (data) => async (dispatch) => {
  const createPlaybookFunction = httpsCallable(functions, "createPlaybook");
  const createRequest = await createPlaybookFunction(data);
  return createRequest.data;
};

// Save Playbook
export const savePlaybook = (playbook) => async (dispatch) => {
  const savePlaybookFunction = httpsCallable(functions, "savePlaybook");
  const saveRequest = await savePlaybookFunction(playbook);
  dispatch({
    type: "SHOW_NOTIFICATION",
    payload: {
      title: "Playbook Saved",
      message: "Your playbook was successfuly saved.",
      type: "static",
      meta: {},
    },
  });
  return saveRequest.data;
};

// Publish Playbook
export const publishPlaybook = (playbook) => async (dispatch) => {
  const publishPlaybookFunction = httpsCallable(functions, "publishPlaybook");
  const publishRequest = await publishPlaybookFunction(playbook);
  dispatch({
    type: "SHOW_NOTIFICATION",
    payload: {
      title: "Playbook Published",
      message: "Your playbook was successfuly published.",
      type: "static",
      meta: {},
    },
  });
  return publishRequest.data;
};

// Publish Playbook with Access Control
export const publishPlaybookWithAccessControl = (playbook) => async (dispatch) => {
  const publishPlaybookWithAccessControlFunction = httpsCallable(functions, "publishPlaybookWithAccessControl");
  const publishRequest = await publishPlaybookWithAccessControlFunction(playbook);
  dispatch({
    type: "SHOW_NOTIFICATION",
    payload: {
      title: "Playbook Published",
      message: "Your playbook was successfuly published.",
      type: "static",
      meta: {},
    },
  });
  return publishRequest.data;
};

// Get Playbook Version History
export const getPlaybookVersionHistory = (playbookId) => async (dispatch) => {
  const getPlaybookVersionHistoryFunction = httpsCallable(
    functions,
    "getPlaybookVersionHistory"
  );
  const getPlaybookVersionHistoryRequest =
    await getPlaybookVersionHistoryFunction({ playbookId: playbookId });
  return getPlaybookVersionHistoryRequest.data;
};

// Get Wizard Multi Select Options
export const getWizardMultiSelectOptions =
  (requestCode, playbookId) => async (dispatch) => {
    const getWizardMultiSelectOptionsFunction = httpsCallable(
      functions,
      "getWizardMultiSelectOptions"
    );

    const data = {
      requestCode: requestCode,
      playbookId: playbookId,
    };

    const getWizardMultiSelectOptionsRequest =
      await getWizardMultiSelectOptionsFunction(data);
    return getWizardMultiSelectOptionsRequest.data;
  };

// Complete Playbook Module Wizard
export const completePlaybookModuleWizard = (data) => async (dispatch) => {
  const completePlaybookModuleWizardFunction = httpsCallable(
    functions,
    "completePlaybookModuleWizard"
  );
  const completePlaybookModuleWizardRequest =
    await completePlaybookModuleWizardFunction(data);
  if (completePlaybookModuleWizardRequest.data.success) {
    dispatch({
      type: "COMPLETE_PLAYBOOK_MODULE_INSERT_CONTENT",
      payload: completePlaybookModuleWizardRequest.data.updatedSection,
    });
  } else {
    dispatch({
      type: "SHOW_NOTIFICATION",
      payload: {
        title: "Error",
        message: "There was an error generating content. Please try again.",
        type: "error",
        meta: {},
      },
    });
  }

  return completePlaybookModuleWizardRequest.data;
};

// THIS IS AN AUTOSAVE FUNCTION THAT WE DON'T CURRENTLY USE OR HAVE The BACKEND FOR
// We will also need to think about how autosave impacts version control
// Save Playbook Module Content (After Editing)
export const savePlaybookModuleContent = (data) => async (dispatch) => {
  const savePlaybookModuleContentFunction = httpsCallable(
    functions,
    "savePlaybookModuleContent"
  );

  // TODO: Why are we calling a function here?
  const savePlaybookModuleContentRequest =
    await savePlaybookModuleContentFunction(data);
  if (savePlaybookModuleContentRequest.data.success) {
    dispatch({
      type: "SAVE_PLAYBOOK_MODULE_CONTENT",
      payload: savePlaybookModuleContentRequest.data.updatedSection,
    });
  } else {
    console.log(savePlaybookModuleContentRequest.data);
  }

  return savePlaybookModuleContentRequest.data;
};
